<template>
	<div class="about">
		<div class="header-row d-sm-block d-md-flex no-gutters">
			<v-col class="col-xs-12 col-md-6 top-img">
				<v-img src="@/assets/hands.webp"/>
			</v-col>
			<v-col class="col-xs-12 col-md-6 hero">
				<!--        <h1>Meet Our <span class="hero-highlight">TEAM</span> </h1>-->
				<h1>What Our Team Does For You</h1>
				<div class="hero-sub col-md-8 col-sm-12">
					<p>Our team provides expertise in office integration, patient education, and billing support so that we can
						offer unparalleled service and support to your clinic and your patients.</p>
					<p>Our goal is always patient AND practice success.</p>
				</div>
			</v-col>
		</div>
		<div class="details d-sm-block d-md-flex about-details">
			<v-col class="details__item col-sm-12 col-md-4">
				<div class="details-header">
					<v-icon class="text-color-green details-header__check">fan fa-check</v-icon>
					INSURANCE VERIFICATION SERVICES
				</div>
				<div class="details">We provide training and support to your office staff with a group of trained professionals
					who contact patient insurances to verify details of coverage.
				</div>
			</v-col>
			<v-col class="details__item col-sm-12 col-md-4">
				<div class="details-header">
					<v-icon class="text-color-green details-header__check">fan fa-check</v-icon>
					INVOICING AND DOCUMENTATION
				</div>
				<div class="details">
					We provide support, training and all necessary documentation for submission of billing and for rebate
					compliance.
				</div>
			</v-col>
			<v-col class="details__item col-sm-12 col-md-4">
				<div class="details-header">
					<v-icon class="text-color-green details-header__check">fan fa-check</v-icon>
					BILLING SUPPORT
				</div>
				<div class="details">
					We provide instruction and support for the billing staff to help them submit billing and reimbursement
					documentation accurately.
				</div>
			</v-col>

			<!-- next row -->
			<v-col class="details__item col-sm-12 col-md-4">
				<div class="details-header">
					<v-icon class="text-color-green details-header__check">fan fa-check</v-icon>
					PRODUCT REIMBURSEMENT GUARANTEE
				</div>
				<div class="details">
					BioLab Sciences offers a product reimbursement guarantee. If reimbursement is denied after collective attempts to receive payment, BioLab will replace the product used at no cost to the clinic.
				</div>
			</v-col>
			<v-col class="details__item col-sm-12 col-md-4">
				<div class="details-header">
					<v-icon class="text-color-green details-header__check">fan fa-check</v-icon>
					PATIENT EDUCATION
				</div>
				<div class="details">
					We provide educational tools and materials in both digital and in-office formats for educating your patients about BioLab products.
				</div>
			</v-col>
			<v-col class="details__item col-sm-12 col-md-4">
				<div class="details-header">
					<v-icon class="text-color-green details-header__check">fan fa-check</v-icon>
					PATIENT OUTREACH
				</div>
				<div class="details">
					We partner with 3rd party groups that specialize in patient outreach via multiple channels such as direct mail, online, and social mediums to help you introduce and educate new and existing patients to your clinic.
				</div>
			</v-col>
		</div>
		<div class="profiles__title">
			<h3 class="spacer-title profiles__title__callout">Meet Our Team</h3>
			<p>Meet our team of science, medical, and business professionals, as well as patient advocates so that we stay informed on both provider AND patient needs.</p>
		</div>

		<div class="profiles d-sm-block d-md-flex">
			<div class="col-sm-12 col-md-6"
					v-for="profile in mainProfiles"
					:key="profile.name"
			>
				<v-card
						class="mx-auto profile__card"
						max-width="434"
						tile
				>

					<div
							class="fill-height"
					>
						<v-col
								class="pa-0"
								cols="12"
						>
							<v-avatar
									class="profile"
									color="grey"
									size="40%"
									tile
									max-height="174"
							>
								<v-img :src="profile.img"></v-img>
							</v-avatar>
							<div class="description col-7">
								<ul v-if="Array.isArray(profile.description)">
									<li v-for="(li, i) in profile.description" :key="profile.name + i">{{ li }}</li>
								</ul>
								<span v-else>{{ profile.description }}</span>
							</div>
						</v-col>
						<v-col class="py-0">
							<v-list-item
									color="rgba(0, 0, 0, .4)"
									dark
							>
								<v-list-item-content>
									<v-list-item-title class="title">
										{{ profile.name }}
									</v-list-item-title>
									<v-list-item-subtitle>{{ profile.title }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-col>
					</div>

				</v-card>
			</div>
			<div class="col-sm-12 col-md-3"
					v-for="profile in profiles"
					:key="profile.name"
			>
				<v-card
						class="mx-auto profile__card"
						max-width="434"
						tile
				>

					<div
							class="fill-height"
					>
						<v-col
								class="pa-0"
								cols="12"
						>
							<v-avatar
									class="profile"
									color="grey"
									size="40%"
									tile
									max-height="174"
							>
								<v-img :src="profile.img"></v-img>
							</v-avatar>
							<div class="description col-7">
								<ul v-if="Array.isArray(profile.description)">
									<li v-for="(li, i) in profile.description" :key="profile.name + i">{{ li }}</li>
								</ul>
								<span v-else>{{ profile.description }}</span>
							</div>
						</v-col>
						<v-col class="py-0">
							<v-list-item
									color="rgba(0, 0, 0, .4)"
									dark
							>
								<v-list-item-content>
									<v-list-item-title class="title">
										{{ profile.name }}
									</v-list-item-title>
									<v-list-item-subtitle>{{ profile.title }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-col>
					</div>

				</v-card>
			</div>
		</div>
<!--		<div class="callout">-->
<!--			<span class="text">EMS Bio, in partnership with DAY RISING, appreciates the time and talent of our amazing Scientific and Medical Advisory Team</span>-->
<!--		</div>-->
	</div>
</template>


<script>
// import seik from '@/assets/profiles/seik.webp';
// import lewis from '@/assets/profiles/lewis.webp';
// import kelly from '@/assets/profiles/kelly.webp';
// import glickert from '@/assets/profiles/glickert.webp';
import brent from '@/assets/profiles/BrentBingham_2012-300x0-c-default.jpg';
import natalyn from '@/assets/profiles/natalyn.png';
import mike from '@/assets/profiles/mike.png';
// import singh from '@/assets/profiles/singh.webp';
// import king from '@/assets/profiles/king.webp';
// import manalansan from '@/assets/profiles/manalansan.webp';
import aWeida from '@/assets/profiles/aWeida.jpeg';

export default {
	name: 'About',
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'EMS Bio and BioLab Sciences Fluid GF Science and Medical Team',
		meta: [
			{
				name: 'description',
				content: 'EMS Bio is dedicated to partnering with the best medical and science advisors we can to provide safe and effective regenerative solutions for your patients.'
			},
		],
		// all titles will be injected into this template
		titleTemplate: '%s'
	},
	props: {},
	data() {
		return {
			mainProfiles: [

			],
			profiles: [
				{
					img: natalyn,
					name: 'Natalyn O. Lewis',
					// title: 'VP Clinic Integrations',
					description: [
						'Graduated from Brigham Young University with a BA in marketing.',
						'22 years of marketing and business experience',
						'Owned and operated 7 different companies',
						'Comes from 3 generations of family practice ownership',
					]
				},
				{
					img: mike,
					name: 'Mike Webb',
					// title: 'VP New Clinic Development',
					description: [
						'Over 30 years of marketing and sales experience',
						'Owned and operated 3 different companies',
						'Over 20 years of real estate development and venture capital ',
					]
				},
				{
					img: aWeida,
					name: 'Avery Weida, N.P.',
					// title: 'Medical Advisory Board',
					description: [
						'Graduated from The University of Tennessee',
						'Practices in Salt Lake City, Utah',
						'Integrative and regenerative medicine provider with focus on non-narcotic pain management',
					]
				},
        {
					img: brent,
					name: 'Brent Bingham',
					// title: 'Experienced Clinic Owner',
					description: [
						'Functional Medicine and non-narcotic pain management',
						'25 years operating businesses in various industries',
						' Sales and Marketing\n' +
            '               Healthcare\n' +
            '               Food and Nutrition',
					]
				},
				// {
				// 	img: seik,
				// 	name: 'Robert Seik, PharmD, FMNM',
				// 	title: 'Chief Science Officer',
				// 	description: [
				// 		'Compounding Pharmacist',
				// 		'Graduated Duquesne University, Pittsburgh, PA',
				// 		'Owns Partell Pharmacy in Las Vegas',
				// 		'Member of The American Academy of of Anti-Aging Medicine (A4M)',
				// 		'Lecturer on regenerative and integrative therapies',
				// 		'10 years in biotech industry managing clinical trials for antiviral biologics',
				// 		'Passionate about helping patients with non-drug strategies',
				// 	]
				// },
				// {
				// 	img: lewis,
				// 	name: 'Dr. Gordon D. Lewis, M.D.',
				// 	title: 'Co-Chief Medical Officer',
				// 	description: [
				// 		'Orthopedic Surgeon for 42 years',
				// 		'Graduated Loma Linda University, CA',
				// 		'Specializes in total joint replacement surgery',
				// 		'Medical Director and partner of Regenesis Centre Inc. of Nampa, ID',
				// 		'Formerly affiliated with Kaiser Permanente',
				// 	]
				// },
				// {
				// 	img: kelly,
				// 	name: 'Dr. Shaunda Kelly, DMD, MD.',
				// 	title: 'Co-Chief Medical Officer',
				// 	description: [
				// 		'Chief of Oral and Maxillofacial Surgery at the department of Veteran Affairs',
				// 		'Owner of Regenerative Medical Institute of Hoover Alabama',
				// 		'Unique experience in biologics such as allograft membrane and amniotic fluid',
				// 	],
				// },
				// {
				// 	img: glickert,
				// 	name: 'Dr. Michael Glickert, D.C.',
				// 	title: 'Medical Advisory Board',
				// 	description: 'Integrated Medical and Functional Medicine Physician.  '
				// },
				// {
				// 	img: gonzalez,
				// 	name: 'Dr. Jorge A. Gonzalez, M.D.',
				// 	title: 'Medical Advisory Board',
				// 	description: '35 years Neurology Specialist\n' +
				// 			'\n' +
				// 			'Tulsa Neuro Specialist, Tulsa, OK.'
				// },
				// {
				//   img: singh,
				//   name: 'Dr. Ardaman Singh, D.C. ',
				//   title: 'Medical Advisory Board',
				//   description: '27 years of Integrated Medical and Functional Medicine Physician.'
				// },
				// {
				// 	img: king,
				// 	name: 'Dr. Corey King, N.D.',
				// 	title: 'Medical Advisory Board',
				// 	description: 'Pure Health Medicine\n' +
				// 			'\n' +
				// 			'Palm Springs, CA'
				// },
				// {
				//   img: manalansan,
				//   name: 'Eric G. Manalansan, P.T.',
				//   title: 'Medical Advisory Board',
				//   description: 'Arthritis Care of Texas'
				// },
			],
		};
	},
};
</script>

<style lang="scss" scoped>
.top-img {
	display: flex;
}

.callout {
	display: flex;
	justify-content: center;
	margin-top: 2rem;

	.text {
		font-size: 15px;
		margin: 1rem;
		text-align: center;
	}

	@media only screen and (max-width: 600px) {
		.text {
			font-size: 12px;
		}
	}
}

.profiles {
	flex-wrap: wrap;

	&__title {
		text-align: center;
		&__callout {
			font-size: 24px;
			color: #507DBC;
		}
	}

	.description {
		color: white;
		height: 150px;
		overflow: auto;
		position: absolute;
		top: 1rem;
		right: 0;
		padding: 0;

		&::-webkit-scrollbar {
			-webkit-appearance: none;
			width: 7px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0, 0, 0, .5);
			box-shadow: 0 0 1px rgba(255, 255, 255, .5);
		}
	}

	@media only screen and (max-width: 600px) {
		.description {
			max-width: 50%;
		}
	}

	.profile {
		&__card {
			//background: #507DBC;
			background: #262262;
		}
	}
}

.about-details {

	border-bottom: 1px solid #507DBC;
}
</style>
